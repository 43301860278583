import * as THREE from 'three';
import { EdgesGeometry } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useContext, useLayoutEffect, useRef } from 'react';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass';
import { DEFAULT_PORT_LIGHTNESS, generateHexColorForHue } from '../../../util/colors';
import PortColorFunction from '../../../context/PortColorFunction';

export default function ThreeDimensionalBayPlan({ selectedBay, plan, polFilter, inOutBound }) {
	const unloToHue = useContext(PortColorFunction);

	const canvasRef = useRef(null);
	const sceneRef = useRef(null);

	useLayoutEffect(() => {
		if (canvasRef.current == null || plan == null) return;
		let killed = false;

		const scene = new THREE.Scene();
		sceneRef.current = scene;
		scene.background = 'transparent';
		// scene.background = null; new THREE.Color(0xFFFFFFFF); // Optional, black is default

		// Set up lights
		const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
		scene.add(ambientLight);

		const dirLight = new THREE.DirectionalLight(0xffffff, 0.25);
		dirLight.position.set(10, 20, 0); // x, y, z
		scene.add(dirLight);

		const light = new THREE.HemisphereLight(0xffffff, 0xffffff, .2);
		scene.add(light);

		// Perspective camera
		const aspect = window.innerWidth / window.innerHeight;
		const camera = new THREE.PerspectiveCamera(
			40, // field of view in degrees
			aspect, // aspect ratio
			1, // near plane
			100000 // far plane
		);

		camera.position.set(0, 100, 140);

		const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		renderer.setClearColor(0xffffff, 0);
		const pixelRatio = window.devicePixelRatio;
		const actualWidth = window.innerWidth - 348;
		const actualHeight = window.innerHeight - 48;
		const desiredWidth = actualWidth * pixelRatio | 0;
		const desiredHeight = actualHeight * pixelRatio | 0;
		renderer.setSize(actualWidth, actualHeight);
		renderer.setSize(desiredWidth, desiredHeight, false);
		renderer.render(scene, camera);

		// Set up orbit mouse controls
		const controls = new OrbitControls(camera, renderer.domElement);
		controls.autoRotate = false;
		controls.autoRotateSpeed = .3;
		controls.target.set(0, 20, 0);
		controls.update();

		const composer = new EffectComposer(renderer);

		const renderPass = new RenderPass(scene, camera);
		composer.addPass(renderPass);

		// This messes up the transparent background, so am disabling this
		// const bloomPass = new UnrealBloomPass(b, 0.15, 1, 1);
		// composer.addPass(bloomPass);

		const smaaPass = new SMAAPass(desiredWidth, desiredHeight);
		composer.addPass(smaaPass);

		function animate() {
			if (killed) return;
			requestAnimationFrame(animate);
			controls.update();
			composer.render();
			camera.updateProjectionMatrix();
		}
		requestAnimationFrame(animate);

		// Add it to HTML
		canvasRef.current.appendChild(renderer.domElement);
		renderer.domElement.style.background = 'rgba(0, 0, 0, 0)';

		const elementToRemove = renderer.domElement;

		return () => {
			killed = true;
			if (canvasRef.current != null && elementToRemove != null) {
				canvasRef.current.removeChild(elementToRemove);
			}
		};
	}, []);

	useLayoutEffect(() => {
		if (plan == null || sceneRef.current == null) return;
		const scene = sceneRef.current;

		// Remove any existing meshes
		for (let i = scene.children.length - 1; i >= 0; i--) {
			if (scene.children[i].type === "Mesh" || scene.children[i].type === 'LineSegments') scene.remove(scene.children[i]);
		}

		// Add the stowage cells to the mesh
		(inOutBound == 'INBOUND' ? plan.inboundCells : plan.outboundCells).forEach(cell => {
			if (cell == null || cell.container == null) return;

			const isFaded = !(polFilter == null || (inOutBound == 'INBOUND' ? cell.container?.portOfDischarge : cell.container?.portOfLoading) == polFilter);

			let materialSpec = { color: 0x555555, transparent: true, opacity: 0.15 };
			if (isFaded) {
				materialSpec.color = 0xcfcfcf;
			} else {
				materialSpec = { color: generateHexColorForHue(unloToHue(cell.container.portOfDischarge), DEFAULT_PORT_LIGHTNESS), transparent: false, opacity: 1 };
			}

			const geometry = new THREE.BoxGeometry(cell.container.length / 1000, cell.container.height / 1000, cell.container.width / 1000); // width, height, depth
			const material = new THREE.MeshPhysicalMaterial(materialSpec);
			const mesh = new THREE.Mesh(geometry, material);
			mesh.position.set(cell.bay * 3.5 - (23 * 3.5), (cell.tier > 80 ? cell.tier - 60 : cell.tier) * 1.6, (cell.row % 2 == 0 ? cell.row / 2 : (-1 * Math.ceil(cell.row / 2))) * 2.6); // Optional, 0,0,0 is the default
			scene.add(mesh);
		});

		if (selectedBay != null) {
			const geometry = new THREE.BoxGeometry((selectedBay % 2 == 0 ? 13 : 6.5), 34 * 1.6, 2.6 * 20); // width, height, depth
			const wireframeGeometry = new EdgesGeometry(geometry);
			const line = new THREE.LineSegments(wireframeGeometry);
			line.material.color = 0x7777ff;
			line.material.opacity = 1;
			line.material.transparent = true;
			line.position.set(selectedBay * 3.5 - (23 * 3.5), 34 / 2 * 1.6, 0); // Optional, 0,0,0 is the default
			scene.add(line);

			const material = new THREE.MeshPhysicalMaterial({ color: 0x7777ff, transparent: true, opacity: 0.2 });
			material.depthWrite = false;
			const mesh = new THREE.Mesh(geometry, material);
			mesh.position.set(selectedBay * 3.5 - (23 * 3.5), 34 / 2 * 1.6, 0); // Optional, 0,0,0 is the default
			scene.add(mesh);
		}
	}, [ plan, selectedBay, polFilter, inOutBound ]);

	return <div className="bayplan-3d-wrapper" ref={canvasRef}>
		{/* <canvas id="bayplan-3d" ref={canvasRef}></canvas>*/}
	</div>;
}