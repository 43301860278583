import { useDrop } from 'react-dnd';
import MiniBay from './bayplanning/MiniBay';
import React, { useEffect, useRef } from 'react';
import { Button } from '@atrocit/scl';

export default function SelectableMiniBay({ voyageData, containerDiggingMap, colorMode, showSwapSuggestions, cellsByCode, shifters, highlightCell, inOutBound, hasTemplate, templateCells, selectedBay, bayNumber, baysNonCollapsed, combinedBays, bay, polFilter, onSelect, blockedCells, editingMode, deleteBay }) {
	const timeoutRef = useRef(null);

	const [ { isHovering }, connectDropRef ] = useDrop(() => ({
		accept: 'CONTAINER',
		canDrop: (item, monitor) => {
			// Allow 20-20ft swaps, and 40-40ft, but not 20-40ft or 40-20ft
			// return cell == null || cell.bay % 2 == item.bayNumber % 2;
			// TODO: implement correct logic for not dragging/dropping across 20ft/40ft bays
			return true;
		},
		collect: monitor => ({
			isHovering: monitor.isOver(),
		}),
	}), [ bayNumber ]);

	useEffect(() => {
		if (!isHovering) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		} else if (timeoutRef.current == null) {
			timeoutRef.current = setTimeout(() => onSelect(), 1000);
		}
	}, [ isHovering ]);

	return <div ref={connectDropRef} className={"bays-item" + (selectedBay == bayNumber ? ' bays-item-selected' : '') + (isHovering ? ' bays-item-hover-over' : '')}>
		<div style={{ textAlign: 'center' }} className="bays-item-title">
			bay {bayNumber} {combinedBays && baysNonCollapsed[bayNumber + 1] != null && <span>+ {bayNumber + 1}</span>}
		</div>
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<MiniBay
				voyageData={voyageData}
				colorMode={colorMode}
				showSwapSuggestions={showSwapSuggestions}
				cellsByCode={cellsByCode}
				combinedBays={combinedBays}
				shifters={shifters}
				highlightCell={highlightCell}
				hasTemplate={hasTemplate}
				templateCells={templateCells}
				bay={bay}
				containerDiggingMap={containerDiggingMap}
				bayNumber={bayNumber}
				// setStatusMsg={setStatusMsg}
				selectedBay={selectedBay}
				inOutBound={inOutBound}
				polFilter={polFilter}
				onSelectBay={onSelect}
				blockedCells={blockedCells}
				editingMode={editingMode}
				deleteBay={deleteBay}/>
		</div>

		{editingMode && <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
			<Button onClick={() => deleteBay(bayNumber)}>Verwijderen</Button>
		</div>}
	</div>;
}