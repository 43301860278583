import { Button, Form, InputField, WithLabel, DateField, ButtonArea, Toggle, ButtonGroup } from '@atrocit/scl';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';
import containerDmgPreview from './container-preview.png';
import atoLogo from './ato_edit.png';
import app from '../App';

export default function CreateDamageReport() {
	const [ printMode, setPrintMode ] = useState({ render: false, selectedOnly: false, overviewOnly: false });

	const [ containerNr, setContainerNr ] = useState('ABCD1234560');
	const [ billOfLading, setBillOfLading ] = useState('');
	const [ vessel, setVessel ] = useState('');
	const [ voyNr, setVoyNr ] = useState('');
	const [ reportDateTime, setReportDateTime ] = useState(DateTime.now().startOf('day'));
	const [ port, setPort ] = useState('BEANR');
	const [ sealNr, setSealNr ] = useState('');
	const [ pol, setPol ] = useState('');
	const [ pod, setPod ] = useState('');
	const [ isoCode, setIsoCode ] = useState('');
	const [ isFull, setIsFull ] = useState(false);
	const [ material, setMaterial ] = useState('STEEL');
	const [ cargo, setCargo ] = useState('');

	const [ applicableDamages, setApplicableDamages ] = useState(new Set());
	const [ remarks, setRemarks ] = useState('');

	const componentToPrint = useRef(null);

	return <div className="page page-fw">
		<div style={{ display: 'grid', gridTemplateColumns: 'auto 210mm', gap: 'var(--u-32)', maxHeight: '95vh', height: '100%', alignItems: 'flex-start', margin: '0 auto', justifyContent: 'center' }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', overflow: 'auto', minHeight: '100%', height: '100%', maxHeight: '100%', width: 'var(--u-512)' }}>
				<div className="dmg-rep-form" style={{ width: 'var(--u-512)', padding: 'var(--u-4)' }}>
					<Form onSubmit={() => {}}>
						<WithLabel label="Containernummer">
							<InputField onChange={setContainerNr} value={containerNr} />
						</WithLabel>
						<WithLabel label="B/L">
							<InputField onChange={setBillOfLading} value={billOfLading} />
						</WithLabel>
						<WithLabel label="Vessel">
							<InputField onChange={setVessel} value={vessel} />
						</WithLabel>
						<WithLabel label="Voyage">
							<InputField onChange={setVoyNr} value={voyNr} />
						</WithLabel>

						<WithLabel label="Datum rapport">
							<DateField onChange={setReportDateTime} value={reportDateTime} />
						</WithLabel>
						<WithLabel label="Haven">
							<InputField onChange={setPort} value={port} />
						</WithLabel>
						<WithLabel label="Seal">
							<InputField onChange={setSealNr} value={sealNr} />
						</WithLabel>
						<WithLabel label="Laadhaven (PoL)">
							<InputField onChange={setPol} value={pol} />
						</WithLabel>
						<WithLabel label="Loshaven (PoD)">
							<InputField onChange={setPod} value={pod} />
						</WithLabel>

						<WithLabel label="ISO-code">
							<InputField onChange={setIsoCode} value={isoCode} />
						</WithLabel>
						<WithLabel label="Volle container">
							<Toggle onChange={f => setIsFull(f)} value={isFull} />
						</WithLabel>
						<WithLabel label="Material">
							<InputField onChange={setMaterial} value={material} />
						</WithLabel>

						<WithLabel label="Cargo">
							<InputField onChange={setCargo} value={cargo} />
						</WithLabel>

						<WithLabel label="Schades" isFake={true}>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-4)' }}>
								<ButtonGroup>
									{(() => {
										const output = [];
										for (let i = 1; i <= 6; i++) {
											const c = i.toString();
											output.push(<Button key={i} active={applicableDamages.has(c)} level={applicableDamages.has(c) ? 'primary' : 'secondary'} onClick={() => (applicableDamages.has(c) ? setApplicableDamages(new Set([ ...applicableDamages ].filter(ad => ad != c))) : setApplicableDamages(new Set([ ...applicableDamages, c ])))}>{c}</Button>);
										}
										return output;
									})()}
								</ButtonGroup>
								<ButtonGroup>
									{(() => {
										const output = [];
										for (let i = 7; i <= 13; i++) {
											const c = i.toString();
											output.push(<Button key={i} active={applicableDamages.has(c)} level={applicableDamages.has(c) ? 'primary' : 'secondary'} onClick={() => (applicableDamages.has(c) ? setApplicableDamages(new Set([ ...applicableDamages ].filter(ad => ad != c))) : setApplicableDamages(new Set([ ...applicableDamages, c ])))}>{c}</Button>);
										}
										return output;
									})()}
								</ButtonGroup>
								<ButtonGroup>
									{(() => {
										const output = [];
										for (let i = 14; i <= 19; i++) {
											const c = i.toString();
											output.push(<Button key={i} active={applicableDamages.has(c)} level={applicableDamages.has(c) ? 'primary' : 'secondary'} onClick={() => (applicableDamages.has(c) ? setApplicableDamages(new Set([ ...applicableDamages ].filter(ad => ad != c))) : setApplicableDamages(new Set([ ...applicableDamages, c ])))}>{c}</Button>);
										}
										return output;
									})()}
								</ButtonGroup>
								<ButtonGroup>
									{(() => {
										const output = [];
										for (let i = 20; i <= 24; i++) {
											const c = i.toString();
											output.push(<Button key={i} active={applicableDamages.has(c)} level={applicableDamages.has(c) ? 'primary' : 'secondary'} onClick={() => (applicableDamages.has(c) ? setApplicableDamages(new Set([ ...applicableDamages ].filter(ad => ad != c))) : setApplicableDamages(new Set([ ...applicableDamages, c ])))}>{c}</Button>);
										}
										return output;
									})()}
								</ButtonGroup>
							</div>
						</WithLabel>
						<br />
						<WithLabel label="Remarks">
							<textarea className="scl-form-element" onChange={e => setRemarks(e.target.value)} value={remarks} style={{ resize: 'vertical', minHeight: '3em' }} />
						</WithLabel>

						<ButtonArea>
							<ReactToPrint
								trigger={() => <Button level="primary"><span className="fa fa-print" />&nbsp; Printen</Button>}
								content={() => componentToPrint?.current} />
						</ButtonArea>
					</Form>
				</div>
			</div>
			<div style={{ overflow: 'auto', height: '100%', maxHeight: '100%' }}>
				<div className="dmg-rep-preview" style={{ border: '1px solid var(--col-grey-300)', minHeight: '297mm' }}>
					<div className="dmg-rep-preview-content" ref={componentToPrint} style={{ padding: '15mm 20mm' }}>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '14pt', paddingTop: 'var(--u-12)', paddingBottom: '8px' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<b>CONTAINER INSPECTION REPORT</b>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', fontSize: '12pt' }}>

							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<img src={atoLogo} style={{ width: '3cm', height: 'auto' }} />
							</div>
							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
								<b>Container:</b><br />
								<i>{containerNr}</i>
							</div>
						</div>
						<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
							<b>B/L: </b><i>{billOfLading}</i>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Vessel: </b><i>{vessel}</i>
							</div>
							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
								<b>Voyage: </b><i>{voyNr}</i>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Report date: </b><i>{reportDateTime.toFormat('yyyy-MM-dd')}</i>
							</div>
							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
								<b>Port: </b><i>BEANR</i>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Seal: </b><i>{sealNr}</i>
							</div>
							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderRight: 0 }}>
								<b>PoL: </b><i>{pol}</i>
							</div>
							<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)' }}>
								<b>PoD: </b><i>{pod}</i>
							</div>
						</div>

						<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt', paddingTop: 'var(--u-12)' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<b>CONTAINER & DAMAGE DESCRIPTION</b>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>ISO code: </b><i>{isoCode}</i>
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>Status: </b><i>{isFull ? 'FULL' : 'EMPTY'}</i>
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>Material: </b><i>{material}</i>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>Cargo: </b><i>{cargo}</i>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
							<div style={{ fontSize: '10pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>Damages</b><br />
								<span>Everything underlined is applicable</span><br /><br />

								<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-4)' }}>
									<div>
										<span style={applicableDamages.has('1') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>1. Corner filling</span><br />
										<span style={applicableDamages.has('2') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>2. Front Top Rail</span><br />
										<span style={applicableDamages.has('3') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>3. Bottom End Rail</span><br />
										<span style={applicableDamages.has('4') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>4. Tunnel</span><br />
										<span style={applicableDamages.has('5') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>5. Cross Members</span><br />
										<span style={applicableDamages.has('6') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>6. Left Side</span><br />
										<br />
										<span style={applicableDamages.has('7') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>7. Front Side</span><br />
										<span style={applicableDamages.has('8') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>8. Top Side Rail</span><br />
										<span style={applicableDamages.has('9') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>9. Tarp Tiedown Rings</span><br />
										<span style={applicableDamages.has('10') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>10. Corner Post</span><br />
										<span style={applicableDamages.has('11') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>11. Side Post</span><br />
										<span style={applicableDamages.has('12') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>12. Fork Pockets</span><br />
										<span style={applicableDamages.has('13') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>13. Hinges</span><br />
									</div>
									<div>
										<span style={applicableDamages.has('14') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>14. Door Gasket</span><br />
										<span style={applicableDamages.has('15') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>15. Locking Bar</span><br />
										<span style={applicableDamages.has('16') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>16. Lock Bar Retainer</span><br />
										<span style={applicableDamages.has('17') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>17. Rear Bottom Rail</span><br />
										<span style={applicableDamages.has('18') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>18. Side Rail</span><br />
										<span style={applicableDamages.has('19') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>19. Door Holdback</span><br />
										<br />
										<span style={applicableDamages.has('20') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>20. Locking Bar Handle</span><br />
										<span style={applicableDamages.has('21') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>21. Corner Post</span><br />
										<span style={applicableDamages.has('22') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>22. Right Side</span><br />
										<span style={applicableDamages.has('23') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>23. Open Top Tarpauling</span><br />
										<span style={applicableDamages.has('24') ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>24. Locking Bar Keeper</span><br />
									</div>
								</div>
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
								<img src={containerDmgPreview} alt="Container" style={{ width: '70mm', height: 'auto' }} />
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', padding: 'var(--u-4)' }}>
								<b>Remarks:</b>
								<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)', paddingTop: '1.5em' }}>
									{remarks}
								</div>
							</div>
						</div>

						<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt', paddingTop: 'var(--u-12)' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<b>SIGNATURES</b>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', fontSize: '12pt' }}>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Vessel:</b>
								<br />
								<br />
								<br />
								<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Terminal:</b>
								<br />
								<br />
								<br />
								<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
								<b>Stevedores:</b>
								<br />
								<br />
								<br />
								<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', padding: 'var(--u-4)' }}>
								<b>Agents:</b>
								<br />
								<br />
								<br />
								<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;
}